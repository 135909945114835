<script setup lang="ts">
    import Button from 'primevue/button';
    import LoginForm from '@/components/login/LoginForm.vue';
    import { useAuthenticationAction } from '@/composables/authentication';
    import { useI18n } from 'vue-i18n';
    import type { LoginCredentials } from '@/model/login-credentials';
    import { LoginResult } from '@/model/login-result';

    const props = defineProps<{
        basketLogin: boolean;
    }>();

    const emits = defineEmits<{
        (event: 'loginSuccess'): void;
        (event: 'forgotPassword'): void;
        (event: 'signup'): void;
    }>();

    const authenticationAction = useAuthenticationAction();
    const { t } = useI18n();

    async function login(credentials: LoginCredentials): Promise<void> {
        const result = await authenticationAction.login(credentials.username, credentials.password);

        if (LoginResult.Success === result) {
            emits('loginSuccess');
        }
    }

    function forgotPassword(): void {
        emits('forgotPassword');
    }
</script>

<template>
    <div class="content-container">
        <div class="split-container split-container-left">
            <div class="login-container">
                <div class="login-container-title">{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.LOGIN_TITLE') }}</div>
                <p v-if="props.basketLogin" class="login-container-description">
                    {{ t('LOGIN.LOGIN_SIGNUP_DIALOG.LOGIN_DESCRIPTION') }}
                </p>
                <LoginForm @submit="login" @forgot-password="forgotPassword"></LoginForm>
            </div>
        </div>
        <div class="split-container">
            <div class="signup-container">
                <div class="signup-container-title">{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_TITLE') }}</div>
                <p>{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_DESCRIPTION') }}</p>
                <ul>
                    <li>{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_DESCRIPTION_LIST_ENTRY_1') }}</li>
                    <li>{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_DESCRIPTION_LIST_ENTRY_2') }}</li>
                    <li>{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_DESCRIPTION_LIST_ENTRY_3') }}</li>
                    <li>{{ t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_DESCRIPTION_LIST_ENTRY_4') }}</li>
                </ul>
                <Button
                    type="submit"
                    data-testid="login-signup-button"
                    outlined
                    class="button"
                    :label="t('LOGIN.LOGIN_SIGNUP_DIALOG.SIGNUP_CTA_BUTTON')"
                    @click="emits('signup')"
                />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .content-container {
        display: flex;
        flex-wrap: wrap;
    }

    .split-container {
        width: 100%;
        padding-bottom: main.$spacing-6;

        &-left {
            border-bottom: 2px solid main.$color-background-bluegray;
        }
    }

    @include main.for-breakpoint-md {
        .content-container {
            flex-wrap: nowrap;
            padding: 0 main.$spacing-5 main.$spacing-5;
        }

        .split-container {
            border: 4px solid main.$color-background-bluegray;
            padding-top: main.$spacing-10;
            padding-bottom: main.$spacing-10;

            &-left {
                background-color: main.$color-background-bluegray;
            }
        }

        .login-container {
            margin-left: main.$spacing-8;
            margin-right: main.$spacing-8;
        }

        .signup-container {
            margin-left: main.$spacing-8;
            margin-right: main.$spacing-8;
        }
    }

    .login-container {
        &-title {
            font-weight: 700;
            font-size: main.$font-size-5;
        }

        &-description {
            margin-bottom: 0;
        }
    }

    .signup-container {
        &-title {
            font-weight: 700;
            font-size: main.$font-size-5;
        }
    }

    .button {
        width: 100%;
        justify-content: center;
    }
</style>
