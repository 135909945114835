<script setup lang="ts">
    import { hasValidationError } from '@/util/hasValidationError';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import FormField from '@/components/form-components/FormField.vue';
    import Button from 'primevue/button';
    import InputText from 'primevue/inputtext';
    import type { LoginCredentials } from '@/model/login-credentials';
    import { object, string } from 'zod';
    import { useI18n } from 'vue-i18n';

    const emits = defineEmits<{
        (event: 'submit', credentials: LoginCredentials): void;
        (event: 'forgotPassword'): void;
    }>();

    const { t } = useI18n();
    const schema = object({
        username: string().email(t('ERROR.INVALID_EMAIL')),
        password: string(),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [username] = defineField('username');
    const [password] = defineField('password');

    const onSubmit = handleSubmit((values) => {
        emits('submit', {
            username: values.username,
            password: values.password,
        });
    });

    function handleForgotPassword(): void {
        emits('forgotPassword');
    }
</script>

<template>
    <form class="form-container" @submit.prevent="onSubmit">
        <div>
            <FormField :label="t('LOGIN.EMAIL')" :error-message="errors.username">
                <InputText
                    v-model="username"
                    class="full-width"
                    data-testid="login-form-email"
                    :invalid="hasValidationError(errors.username)"
                />
            </FormField>
            <FormField :label="t('LOGIN.PASSWORD')" :error-message="errors.password">
                <InputText
                    v-model="password"
                    class="full-width"
                    data-testid="login-form-password"
                    type="password"
                    :invalid="hasValidationError(errors.password)"
                />
            </FormField>
        </div>
        <div class="button-container">
            <Button type="submit" class="button" data-testid="login-form-login-button" :label="t('LOGIN.LOGIN')" />
            <Button
                type="button"
                class="button"
                text
                :label="t('LOGIN.FORGOT_PASSWORD.TITLE')"
                @click="handleForgotPassword"
            />
        </div>
    </form>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .form-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-6;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-6;
    }

    .button {
        width: 100%;
        justify-content: center;
    }
</style>
